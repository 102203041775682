import { Link } from 'gatsby'
import { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import img1 from '../images/projects/img1.png'
import img2 from '../images/projects/img2.png'
import img3 from '../images/projects/img3.png'
import avater from '../images/common/avater.png'
import SpinLoading from '../components/SpinLoading'

const Projects: React.FC = () => {
  const [cardLoading, setCardLoading] = useState(true)
  const [optionList, setOptionList] = useState([
    { optionName: 'All', checked: true },
    { optionName: 'Ongoing', checked: false },
    { optionName: 'Upcoming', checked: false },
    { optionName: 'Finished', checked: false },
  ])
  useEffect(() => {
    setCardLoading(true)
    new Promise(resolve => setTimeout(resolve, 1200)).then(() =>
      setCardLoading(false),
    )
  }, [])

  return (
    <Layout>
      <div className="font-regularText">
        <div className="mt-20 bg-[#F3F7FA] sm:mt-[57px]">
          <div className="max-w-7xl mx-auto md:px-16 pt-16 pb-[60px] sm:px-4 sm:pt-[30px] sm:pb-[60px]">
            {/* Options List */}
            <div className="h-[42px] text-base mb-[50px] sm:w-full sm:flex sm:flex-nowrap sm:overflow-y-auto sm:scrollbar-no-style sm:mb-0">
              {optionList.map(x => (
                <button
                  className={`px-[31px] py-[14px] mr-8 rounded-[35px] last:mr-0 sm:mr-3 sm:text-[14px] sm:leading-[14px] ${
                    x.checked
                      ? 'bg-[#DBEEFF] text-[#1E94FC]'
                      : 'bg-white text-[#828282]'
                  }`}
                  onClick={async () => {
                    setCardLoading(true)
                    setOptionList(
                      optionList.map(y =>
                        y.optionName !== x.optionName
                          ? { ...y, checked: false }
                          : { ...y, checked: true },
                      ),
                    )
                    await new Promise(resolve => setTimeout(resolve, 1200))
                    setCardLoading(false)
                  }}
                >
                  {x.optionName}
                </button>
              ))}
            </div>
            {/* Content Card */}
            <div className="mt-[50px] grid gap-[30px] grid-cols-3 sm:mt-[30px] sm:grid-cols-1">
              {new Array(9).fill('').map(() => (
                <div className="bg-white rounded-2xl p-[30px] text-sm shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] hover:shadow-[0_16px_24px_1px_rgba(0,0,0,0.1)] hover:-translate-x-1 hover:-translate-y-2 transition-all duration-300 group cursor-pointer h-[456px] sm:h-[376px] sm:p-0">
                  {cardLoading ? (
                    <div className="flex w-full h-full">
                      <SpinLoading className="w-auto h-auto m-auto" />
                    </div>
                  ) : (
                    <>
                      <div className="w-full h-[209px] bg-slate-500 rounded-2xl relative overflow-hidden cursor-pointer group sm:rounded-b-none sm:h-[184px] ">
                        <img
                          src={
                            [img1, img2, img3][
                              Math.floor(
                                Math.random() *
                                  (Math.floor(2) - Math.ceil(0) + 1),
                              ) + Math.ceil(0)
                            ]
                          }
                          alt="img"
                          className="w-full h-[209px]"
                        />
                        <div className="absolute top-0 w-full h-full group-hover:bg-[rgba(0,0,0,0.7)]"></div>
                        {/* Mask Button */}
                        <div className="absolute flex items-center justify-center w-full h-full transition-all duration-300 top-full group-hover:top-0">
                          <div className="flex h-[44px] text-white">
                            <Link
                              to="/projectsDetails"
                              className="flex items-center justify-center w-[104px] bg-[rgba(255,255,255,0.59)] rounded-[31px] hover:bg-[#1E94FC]"
                            >
                              Details
                            </Link>
                            <Link
                              to="/projectsDetails"
                              className="flex items-center justify-center w-[104px] bg-[rgba(255,255,255,0.59)] hover:bg-[#1E94FC] rounded-[31px] ml-[10px]"
                            >
                              Participate
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="mt-[37px] bg-[#FAFAFA] py-[22px] px-[14px] rounded sm:mt-6 sm:mx-4 sm:py-3 sm:px-[14px]">
                        Epic War is a 3D blockchain cinematic game and
                        collectibles, offering a game, comic book, film, and
                        NFTs all at once. re Solana, Poly...
                      </div>
                      <div className="mt-[31px] flex justify-between sm:mt-[23px] sm:px-4 sm:mb-[31px]">
                        <div className="flex items-center">
                          <img
                            src={avater}
                            alt="avater"
                            className="w-6 h-6 rounded-full bg-slate-500"
                          />
                          <div className="ml-[6px]">BNB Chain</div>
                        </div>
                        <div className="flex items-center">
                          <div className="text-[#828282]">Token Price</div>
                          <div className="text-[#333] ml-2 font-numberText group-hover:text-[#1E94FC]">
                            $30.00
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Projects
